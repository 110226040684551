

















































































































































import { defineComponent } from "@vue/composition-api";
import { getProducersList } from "@/api/organizations/producer";
import { IProducerDetails } from "@/lib/types/organizations/producer";
import { dynamicSort, seperateThousand, slugify } from "@/utils";
import { OrganizationTypes } from "@/lib/types/base";
// import ProducerCrudFormVue from "@/components/forms/ProducerCrudForm.vue";

export default defineComponent({
  name: "ProducersList",
  components: {
    ProducerListFilter: () => import("@/components/producer/ProducerListFilter.vue"),
    ProducerCrudForm: () => import("@/components/forms/ProducerCrudForm.vue"),
    ProducerDetails: () => import("@/components/producer/ProducerDetails.vue"),
  },
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      if ([OrganizationTypes.RETAILER].includes(vm.$store.state.user.details.organization_type)) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    });
  },
  data () {
    return {
      producers: [] as IProducerDetails[],
      producersLoaded: false,
      producerDetails: null,
      limit: 24,
      offset: 0,
      search: "",
      openFilter: false,
      createModalVisible: false,
      detailsModalVisible: false,
      filters: {
        city: [] as string[],
      },
      filterSets: {
        city: [] as string[],
      },
      isRouterAlive: true,
      producerCrudFormValue: false,
    };
  },
  computed: {
    hasFilters () {
      return Object.values(this.filterSets as Record<string, any[]>).some((element: any[]) => {
        return element.length > 1;
      });
    },
    roles () {
      return this.$store.getters["user/roles"];
    },
    filteredList () {
      let results = this.producers
        ? [...(this.producers as IProducerDetails[]).sort(dynamicSort("-created_at"))]
        : [];

      if (this.search.length) {
        results = results.filter(res => {
          return (
            slugify(res.name).includes(slugify(this.search)) ||
            slugify(res.blockchain_account_address).includes(slugify(this.search)) ||
            slugify(res.id).includes(slugify(this.search))
          );
        });
      }

      if (this.filters.city.length) {
        results = results.filter(res => {
          return this.filters.city.includes(res.location.city);
        });
      }

      return results;
    },
  },
  methods: {
    async producersListDataFn () {
      this.producersLoaded = false;

      await getProducersList({
        limit: this.limit,
        offset: this.offset,
      })
        .then(res => {
          if (res.results) {
            this.producers = res.results.sort(dynamicSort("-created_at"));
          }

          this.producersLoaded = true;
        })
        .catch(err => console.error(JSON.stringify(err.body.errors.detail)));
    },
    siteCreated () {
      this.producersListDataFn();
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
      this.createModalVisible = false;
    },
    siteUpdated () {
      this.producersListDataFn();
      this.createModalVisible = false;
    },
    openDetailsModal (row: any) {
      this.detailsModalVisible = true;
      this.producerDetails = row;
    },
    changeF(newVal: any) {
      this.producerCrudFormValue = newVal;
    },
    closeDialog() {
      if (this.producerCrudFormValue) {
        this.$confirm(
          /* Message */
          this.$i18n.t("messages.sure_to_close") as string,
          /* Title */
          this.$i18n.t("labels.warning") as string,
          {
            confirmButtonText: "OK",
            cancelButtonText: this.$i18n.t("labels.cancel") as string,
            type: "warning",
            center: true,
            showClose: false,
            dangerouslyUseHTMLString: true,

            beforeClose: async (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                this.isRouterAlive = false;
                this.$nextTick(() => {
                  this.isRouterAlive = true;
                });
                this.createModalVisible = false;
                instance.confirmButtonLoading = false;
                this.producerCrudFormValue = false;
                done();
                /*  */
              } else {
                done();
              }
            },
          });
      } else {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        });
        this.createModalVisible = false;
        this.producerCrudFormValue = false;
      }
    },
  },
  mounted () {
    this.producersListDataFn();
  },
  watch: {
    producers: {
      handler (newVal) {
        if (newVal) {
          const citySet = new Set<string>();

          this.producers.forEach(producer => {
            citySet.add(producer.location.city);
          });

          this.filterSets.city = [...citySet].sort();
        }
      },
      deep: true,
    },
  },
  setup () {
    return { seperateThousand, OrganizationTypes };
  },
});
